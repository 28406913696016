mobile-mb-nav-menu *,
mb-nav-menu * {
  box-sizing: border-box;
}

mobile-mb-nav-menu a:hover,
mobile-mb-nav-menu button:hover,
mb-nav-menu button:hover,
mb-nav-menu button:hover {
  cursor: pointer;
}

@media (min-width: 1025px) {
  mobile-mb-nav-menu {
    display: none;
  }
}

mb-nav-menu {
  display: none;
}

@media (min-width: 1025px) {
  mb-nav-menu {
    display: block;
  }
}

.mb-nav-menu__nonStackingContext {
  transform: none !important;
}

.mb-nav-menu__expandBtn:focus,
.mb-nav-menu__searchBtn:focus,
.mb-nav-menu__link:focus,
.mb-nav-menu__promoLink:focus,
.mb-nav-menu__closeBtn:focus,
.mb-nav-menu__logo:focus {
    box-shadow: none !important;
    outline: none !important;
}

/* NAV */

.mb-nav-menu__nav {
  width: 100%;
}

@media (min-width: 1025px) {
  .mb-nav-menu__nav {
    background: var(--sand-light-500);
    border-bottom-right-radius: 120px;
  }
}

.mb-nav-menu__nav .mb-nav-menu__chevron path {
  fill: #828282;
}

[aria-expanded="true"].mb-nav-menu__expandBtn .mb-nav-menu__chevron {
  transform: rotate(180deg);
}

.mb-nav-menu__submenu [aria-expanded="false"].mb-nav-menu__expandBtn.mb-nav-menu__layer-2 .mb-nav-menu__chevron,
.mb-nav-menu__submenu [aria-expanded="true"].mb-nav-menu__expandBtn.mb-nav-menu__layer-2 .mb-nav-menu__chevron,
.mb-nav-menu__submenu [aria-expanded="false"].mb-nav-menu__expandBtn.mb-nav-menu__layer-3 .mb-nav-menu__chevron,
.mb-nav-menu__submenu [aria-expanded="true"].mb-nav-menu__expandBtn.mb-nav-menu__layer-3 .mb-nav-menu__chevron {
  transform: rotate(-90deg);
}

/* HEADER */

.mb-nav-menu__header {
  background-color: var(--neutral-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 16px;
  border-bottom: 1px solid var(--steel-grey-light-200);
}

@media (min-width: 1025px) {
  .mb-nav-menu__header {
    position: relative;
    padding: 0;
    justify-content: center;
  }
}

/* LOGO */

.mb-nav-menu__logo {
  display: flex;
}

/* MENU */

.mb-nav-menu__menuWrapper {
  display: flex;
  align-items: center;
}

.mb-nav-menu__menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
}

@media (min-width: 1025px) {
  .mb-nav-menu__menu {
    display: flex;
    flex-direction: row;
    margin-left: 50px;
    gap: 24px;
    overflow: initial;
  }
}

/* SUBMENU */

.mb-nav-menu__submenu {
  display: none;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.mb-nav-menu__submenu.mb-nav-menu__open {
  display: block;
}

@media (min-width: 1025px) {
  .mb-nav-menu__submenu {
    width: max-content;
    min-width: 330px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .mb-nav-menu__submenu.mb-nav-menu__layer-3,
  .mb-nav-menu__submenu.mb-nav-menu__layer-4,
  .mb-nav-menu__submenu.mb-nav-menu__layer-5 {
    padding-top: 16px;
  }
}

.mb-nav-menu__submenu.mb-nav-menu__open .mb-nav-menu__submenu.mb-nav-menu__open {
  visibility: visible;
  transform: none;
}

.mb-nav-menu__submenu .mb-nav-menu__submenu {
  display: block;
  transition: all .3s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1000;
  visibility: hidden;
  transform: translateX(100vw);
  background-color: var(--neutral-white);
}

@media (min-width: 1025px) {
  .mb-nav-menu__submenu {
    position: absolute;
    left: 0;
    top: 100%;
    border-right: 1px solid var(--steel-grey-light-300);
  }

  .mb-nav-menu__submenu .mb-nav-menu__submenu {
    display: none;
    overflow: initial;
    transition: none;
    visibility: visible;
    position: absolute;
    left: 100%;
    top: 1px;
    bottom: initial;
    right: initial;
    background: none;
  }

  .mb-nav-menu__submenu.mb-nav-menu__open .mb-nav-menu__submenu.mb-nav-menu__open {
    transform: none;
    display: block;
  }
}

.mb-nav-menu__submenuItem {
  color: var(--primary-dark);
}

.mb-nav-menu__submenu .mb-nav-menu__link {
  text-decoration: none;
  color: var(--primary-dark);
  display: block;
  padding: 24px 20px 24px 32px;
  background: rgba(246, 244, 242, 1);
  border-bottom: 1px solid var(--steel-grey-light-200);
  font-size: 16px;
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
}

@media (min-width: 1025px) {
  .mb-nav-menu__submenu .mb-nav-menu__link {
    background: none;
    border: none;
    padding: 20px 12px;
    font-size: 18px;
  }
}

/* EXPAND BUTTON */

.mb-nav-menu__expandBtn {
  background: none;
  padding: 0;
  margin: 0;
  border:none;
  color: inherit;
  font-size: inherit;
}

.mb-nav-menu__chevron {
  display: flex;
}

.mb-nav-menu__menuItem .mb-nav-menu__expandBtn,
.mb-nav-menu__menuItem .mb-nav-menu__link {
  display: block;
  text-align: left;
  width: 100%;
  padding: 24px 20px 24px 32px;
  background-color: var(--sand-light-700);
  border-bottom: 1px solid var(--steel-grey-light-200);
}

@media (min-width: 1025px) {
  .mb-nav-menu__menuItem .mb-nav-menu__expandBtn,
  .mb-nav-menu__menuItem .mb-nav-menu__link {
    display: block;
    background: none;
    border: none;
    padding: 20px 12px;
    font-size: 18px;
  }
}

.mb-nav-menu__menuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-1,
.mb-nav-menu__menuItem .mb-nav-menu__link.mb-nav-menu__layer-1 {
  text-decoration: none;
  font-family: var(--domaine-display-regular);
  color: var(--princess-blue);
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 11px;
  line-height: 1;
}

.mb-nav-menu__menuItem .mb-nav-menu__expandBtn.deactive.mb-nav-menu__layer-1:focus,
.mb-nav-menu__menuItem .mb-nav-menu__link.deactive.mb-nav-menu__layer-1:focus {
    background-color: white;
    text-decoration: underline;
    cursor: pointer;
}

[aria-expanded="true"].mb-nav-menu__expandBtn.mb-nav-menu__layer-1 .mb-nav-menu__expandBtnText {
  font-family: var(--domaine-display-bold);
}

[aria-expanded="true"].mb-nav-menu__layer-1 path {
  fill: var(--princess-blue);
}

[aria-expanded="true"].mb-nav-menu__layer-2 path {
  fill: var(--primary-dark);
}

@media (min-width: 1025px) {
  .mb-nav-menu__menuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-1,
  .mb-nav-menu__menuItem .mb-nav-menu__link.mb-nav-menu__layer-1,
  .mb-nav-menu__submenuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-3,
  .mb-nav-menu__submenuItem .mb-nav-menu__link.mb-nav-menu__layer-3
   {
    font-size: 18px;
  }
  .mb-nav-menu__submenuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-2,
  .mb-nav-menu__submenuItem .mb-nav-menu__link.mb-nav-menu__layer-2 {
    font-size: 22px;
  }
}

.mb-nav-menu__submenuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-2,
.mb-nav-menu__submenuItem .mb-nav-menu__link.mb-nav-menu__layer-2,
.mb-nav-menu__submenuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-3,
.mb-nav-menu__submenuItem .mb-nav-menu__link.mb-nav-menu__layer-3 {
  text-decoration: none;
  color: var(--primary-dark);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--neutral-white);
  padding: 20px 30px 20px 56px;
  font-size: 16px;
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
  line-height: 1;
}

@media (min-width: 1025px) {
  .mb-nav-menu__submenuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-2,
  .mb-nav-menu__submenuItem .mb-nav-menu__link.mb-nav-menu__layer-2,
  .mb-nav-menu__submenuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-3,
  .mb-nav-menu__submenuItem .mb-nav-menu__link.mb-nav-menu__layer-3 {
    background: none;
    padding: 20px;
  }
}

/* SEARCH BUTTON */

.mb-nav-menu__searchBtn {
  display: none;
  padding: 12px;
  border: 3px solid var(--accent-pink);
  border-radius: 100px;
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-bold);
	font-style: var(--font-style-normal);
  font-size: 20px;
  line-height: 1;
  width: 100%;
  background-color: var(--neutral-white);
  color: var(--primary-dark);
}

@media (min-width: 1025px) {
  .mb-nav-menu__searchBtn {
    display: block;
    max-width: 200px;
  }
}

.mb-nav-menu__nav.mb-nav-menu__open .mb-nav-menu__searchBtn {
  display: block;
}

/* BURGER BUTTON */

.mb-nav-menu__burgerBtn {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 font-family: var(--proxima-nova-adobe);
 font-weight: var(--font-weight-regular);
 font-style: var(--font-style-normal);
 font-size: 14px;
 color: var(--primary-dark);
 padding: 0;
 margin: 0;
 background: none;
 border: none;
}

.mb-nav-menu__burgerBtn.mb-nav-menu__open {
  display: none;
}

.mb-nav-menu__burgerBtnText {
  margin-top: 6px;
}

/* CLOSE BUTTON */
.mb-nav-menu__closeBtn {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
  font-size: 14px;
  color: var(--primary-dark);
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

.mb-nav-menu__closeBtnText {
  margin-top: 6px;
}

.mb-nav-menu__nav.mb-nav-menu__open .mb-nav-menu__closeBtn {
  display: flex;
}

.mb-nav-menu__nav .mb-nav-menu__closeBtn.mb-nav-menu__desktop {
  display: none;
  position: absolute;
  top: 20px;
}

@media (min-width: 1025px) {
  .mb-nav-menu__header.mb-nav-menu__open .mb-nav-menu__closeBtn.mb-nav-menu__desktop {
    display: block;
  }
}

/* MENU CONTROL PANEL */

.mb-nav-menu__menuControlPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  border-bottom: 1px solid var(--steel-grey-light-200);
}

@media (min-width: 1025px) {
  .mb-nav-menu__menuControlPanel {
    display: none;
  }
}

.mb-nav-menu__menuControlPanelBackBtn {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
  font-size: 14px;
  color: var(--primary-dark);
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

.mb-nav-menu__menuControlPanelBackBtnText {
  margin-top: 6px;
}

.mb-nav-menu__menuControlPanelTitle {
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-bold);
	font-style: var(--font-style-normal);
  font-size: 20px;
  color: var(--primary-dark);
  text-decoration: underline;
}

.mb-nav-menu__menuControlPanelCloseBtn {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
  font-size: 14px;
  color: var(--primary-dark);
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

.mb-nav-menu__menuControlPanelCloseBtnText {
  margin-top: 6px;
}

/* PROMO */

.mb-nav-menu__promo {
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
  color: var(--primary-dark);
  padding: 24px 19px;
}

@media (min-width: 1025px) {
  .mb-nav-menu__promo {
    position: absolute;
    top: 1px;
    left: 0;
    padding: 20px;
  }
}

.mb-nav-menu__promoImg {
  display: block;
  height: 100%;
  max-height: 258px;
  object-fit: contain;
  max-height: 176px;
  margin-bottom: 24px;
}

@media (min-width: 1025px) {
  .mb-nav-menu__promoImg {
    max-height: 258px;
    margin-bottom: 13px;
  }
}

.mb-nav-menu__promoTitle {
  font-family: var(--domaine-display-bold);
  color: var(--princess-blue);
  text-align: left;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
}

@media (min-width: 1025px) {
  .mb-nav-menu__promoTitle {
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.03em;
  }
}

.mb-nav-menu__promoDescription,
.mb-nav-menu__promoDescription p {
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 16px;
}

@media (min-width: 1025px) {
  .mb-nav-menu__promoDescription,
  .mb-nav-menu__promoDescription p {
    font-size: 18px;
    line-height: 27px;
  }
}

.mb-nav-menu__promoLink {
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
  text-align: left;
  color: var(--primary-dark);
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: 1025px) {
  .mb-nav-menu__promoLink {
    font-size: 18px;
    line-height: 27px;
  }
}

/* TOOLTIP */
@media (min-width: 1025px) {

.mb-nav-menu__link,
.mb-nav-menu__expandBtn {
  position: relative;
}

.mb-nav-menu__link:hover .mb-nav-menu__tooltip,
.mb-nav-menu__expandBtn:hover .mb-nav-menu__tooltip {
  display: block;
}

.mb-nav-menu__tooltip {
  width: max-content;
  max-width: 525px;
  text-align: center;
  display: none;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 16px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: var(--primary-dark);
  background-color: var(--neutral-white);
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
  border-radius: 8px;
  z-index: 10000;
}

.mb-nav-menu__tooltip::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mb-nav-menu__tooltip.disabled {
  visibility: hidden;
}

.mb-nav-menu__tooltip.mb-nav-menu__up::before {
  border-color: var(--neutral-white) transparent transparent transparent;
  top: calc(100% + 8px);
}

.mb-nav-menu__tooltip.mb-nav-menu__down::before {
  border-color: transparent transparent var(--neutral-white) transparent;
  bottom: calc(100% - 12px);
}
}

@media only screen and (max-width: 1024px) {
  .mb-nav-menu__tooltip,
.mb-nav-menu__expandBtn:disabled {
  color: var(--steel-grey-light-900) !important;
  padding-top: 3px;
  font-size: 16px;
  font-family: var(--proxima-nova-adobe);
	font-weight: var(--font-weight-regular);
	font-style: var(--font-style-normal);
}
.mb-nav-menu__menuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-1 {
    justify-content: space-between;
  }
  .mb-nav-menu__menuItem.mb-nav-menu__layer-1 .mb-nav-menu__expandBtnText, .mb-nav-menu__menuItem.mb-nav-menu__layer-1 .mb-nav-menu__link.mb-nav-menu__layer-1 {
    font-family: var(--domaine-display-bold);
  }
  .mb-nav-menu__layer-2 .mb-nav-menu__expandBtnText, .mb-nav-menu__layer-3 .mb-nav-menu__expandBtnText{
    font-family: var(--proxima-nova-adobe) !important;
	  font-weight: var(--font-weight-regular);
	  font-style: var(--font-style-normal);
  }
  .mb-nav-menu__link.hasTooltip {
    flex-direction: column;
    text-align: left;
    align-items: start !important;
    justify-content: left !important;
  }
  .mb-nav-menu__tooltip.disabled {
    display: none;
  }

}

@media only screen and (min-width: 1025px) and (max-width: 1280px) {
  .mb-nav-menu__menu {
    gap: 10px !important;
    margin: 0 !important;
  }
  .mb-nav-menu__menuItem .mb-nav-menu__expandBtn.mb-nav-menu__layer-1, .mb-nav-menu__menuItem .mb-nav-menu__link.mb-nav-menu__layer-1 {
    gap: 2px !important;
    padding: 20px 5px !important;
  }
}

.nav-menu__submenuItem.nav-menu__layer-3 .nav-menu__linkText,
.nav-menu__submenuItem.nav-menu__layer-3 .nav-menu__expandBtnText {
    font-size: 16px;
}

@media (min-width: 1025px) {
  .nav-menu__submenuItem.nav-menu__layer-3 .nav-menu__linkText,
  .nav-menu__submenuItem.nav-menu__layer-3 .nav-menu__expandBtnText {
    font-size: 18px;
  }
}

[aria-expanded="true"] .mb-nav-menu__expandBtnText {
  position: relative;
  text-decoration: none;
  display: inline-block;
  transition: color ease 0.3s;
}

[aria-expanded="true"] .mb-nav-menu__expandBtnText::before, [aria-expanded="true"] .mb-nav-menu__expandBtnText::after {
  content: '';
  position: absolute;
  background-color: var(--primary-dark);
  height: 2px;
}

[aria-expanded="true"].mb-nav-menu__layer-1 .mb-nav-menu__expandBtnText::before, [aria-expanded="true"].mb-nav-menu__layer-1 .mb-nav-menu__expandBtnText::after {
  background-color: var(--accent-pink);
  height: 4px;
}

[aria-expanded="true"] .mb-nav-menu__expandBtnText::before {
  width: 0%;
  left: 0;
  bottom: -6px;
  transition: width ease 0.4s;
}

[aria-expanded="true"] .mb-nav-menu__expandBtnText::after {
  width: 100%;
  left: 0;
  bottom: -6px;
  transition: all ease 0.6s;
}

[aria-expanded="true"]:hover .mb-nav-menu__expandBtnText::before {
  width: 100%;
}

[aria-expanded="true"]:hover .mb-nav-menu__expandBtnText::after {
  left: 100%;
  width: 0%;
  transition: all ease 0.2s;
}

[aria-expanded="false"] .mb-nav-menu__expandBtnText, .mb-nav-menu__linkText {
  display: inline-block;
  position: relative;
}

[aria-expanded="false"] .mb-nav-menu__expandBtnText::after,
.mb-nav-menu__linkText::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: var(--primary-dark);
  transition: transform 0.25s ease-out;
}

[aria-expanded="false"].mb-nav-menu__layer-1 .mb-nav-menu__expandBtnText::after,
.mb-nav-menu__layer-1.mb-nav-menu__link .mb-nav-menu__linkText::after {
  background-color: var(--accent-pink);
  height: 4px;
}

[aria-expanded="false"].mb-nav-menu__expandBtn:hover .mb-nav-menu__expandBtnText::after,
.mb-nav-menu__link:hover .mb-nav-menu__linkText::after {
  transform: scaleX(1);
  transition: transform 0.35s ease-in;
}

[aria-expanded="false"] .mb-nav-menu__expandBtnText::after,
.mb-nav-menu__linkText::after {
  transform-origin: bottom right;
}

[aria-expanded="false"].mb-nav-menu__expandBtn:hover .mb-nav-menu__expandBtnText::after,
.mb-nav-menu__link:hover .mb-nav-menu__linkText::after {
  transform-origin: bottom left;
}
